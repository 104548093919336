import React, { useCallback, memo, useState, useEffect, useRef, useMemo } from 'react';
import produce from 'immer';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import useStore from '../../store';


const LogicItem = memo(({ id }) => {
  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);
  const removeConnectionsWithID = useStore(state => state.removeConnectionsWithID);

  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeConnectionsWithID(id);
    removeItem(id);
  }

  const setInput = (inputID, itemID) => {
    setItem(id, produce(item, draft => {
      draft.params.nodes[inputID] = itemID;
    }))
  }

  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title}`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between pt-2">
        <div>
          {item.inputAnchors.map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
        <div>
          {item.outputAnchors.map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
      </div>
    </DraggableItem>
  )
})

export default LogicItem;