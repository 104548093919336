import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import './App.css';
import Nav from './components/Nav';
import ItemConnections from './components/ItemConnection';
import GateItem from './components/Items/GateItem';
import OutputItem from './components/Items/OutputItem';
import Dashboard from './components/Dashboard';
import Controls from './components/Controls';
import { Button } from './components/helper/helperComponents'

import { genRandomPatient } from './components/helper/helperFunctions';

import * as ITEMTYPES from './constants/itemTypes';
import { itemColors } from './constants/itemProperties';

import * as VALUETYPES from './constants/valueTypes';

import useStore from './store';

import ConstantItem from './components/Items/ConstantItem';
import DebugItem from './components/Items/DebugItem';
import VariableItem from './components/Items/VariableItem';
import LogicItem from './components/Items/LogicItem';
import SVGContainer from './components/SVGContainer';
import ItemBackgroundGrid from './components/ItemBackgroundGrid';


const textBlocks = {
  block1okay: "Der Patient ist ganz okay",
  block2meh: "Der Patient ist doof",
  block3schlecht: "Der Patient muss leider eingeschläfert werden"
}


function App() {

  const items = useStore(state => state.items);
  const setItems = useStore(state => state.setItems);
  
  const connections = useStore(state => state.connections);
  const setConnections = useStore(state => state.setConnections);

  const context = useStore(state => state.context);
  const setContext = useStore(state => state.setContext);

  const setTextBlocks = useStore(state => state.setTextBlocks);


  const regenContext = async () => {
    const patientData = await genRandomPatient()
    setContext(patientData);
  }

  useEffect(() => {
    setTextBlocks(textBlocks);

    regenContext();

  },[]);

  console.log(items);

  const [stringified, setStringified] = useState("");
  const [stringifiedConnections, setStringifiedConnections] = useState("");

  const safeItems = () => {
    setStringified(JSON.stringify(items));
    setStringifiedConnections(JSON.stringify(connections));
    setConnections([]);
    setItems({});
  }

  const loadItems = () => {
    const itemsObject = JSON.parse(stringified);
    const connectionsArray = JSON.parse(stringifiedConnections);
    setItems(itemsObject);
    setConnections(connectionsArray);
  }

  return (
    <>
      <Nav />
      <Dashboard />
      <Controls />
      <div className='container mx-auto px-6'>
        <Button color="teal" onClick={safeItems}>Save State</Button>
        <Button color="teal" onClick={loadItems}>Load State</Button> 
      </div>


      <div className="relative overflow-scroll container mx-auto px-6 py-2 h-screen">
        <div style={{height:2000, width: 3600}}>
          {
            Object.keys(items).map(ind => {
              switch (items[ind].type) {
                case ITEMTYPES.CONSTANT:
                  return <ConstantItem key={ind} id={ind} />
                case ITEMTYPES.VARIABLE:
                  return <VariableItem key={ind} id={ind} />
                case ITEMTYPES.OUTPUT:
                  return <OutputItem key={ind} id={ind} />
                case ITEMTYPES.LOGIC:
                  return <LogicItem key={ind} id={ind} />
                case ITEMTYPES.DEBUG:
                  return <DebugItem key={ind} id={ind} />
                default:
                  return <GateItem key={ind} id={ind} />
              }
            })
          }
          <SVGContainer>
            <ItemBackgroundGrid />
            <ItemConnections />
          </SVGContainer>
        </div>
      </div>
    </>
  );
}

export default App;
