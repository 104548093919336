import React, {memo} from 'react';


const SVGContainer = memo(({children}) => {
  return (
    <svg className="w-full h-full">
      {children}
    </svg>
  )
})

export default SVGContainer;