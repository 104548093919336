import React from 'react';
import useStore from '../../store';

import * as ITEMTYPES from '../../constants/itemTypes';
import { itemColors } from '../../constants/itemProperties';

import * as VALUETYPES from '../../constants/valueTypes';

import { generateConstantDefaultItem, generateDebugDefaultItem, generateVariableDefaultItem, generateLogicDefaultItem } from '../helper/itemDefaultFunctions';

import { Button } from '../helper/helperComponents';
import { genRandomPatient, generateRandomID } from '../helper/helperFunctions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const Controls = () => {
  const context = useStore(state => state.context);
  const setContext = useStore(state => state.setContext);
  const addItem = useStore(state => state.addItem);



  const addConstant = (valueType) => {
    const id = generateRandomID()

    const constantItem = generateConstantDefaultItem(valueType);


    addItem(id, constantItem);
  }

  const addVariable = (valueType) => {
    const id = generateRandomID();
    const variableItem = generateVariableDefaultItem(valueType);

    addItem(id, variableItem);
  }

  const addANDLogic = () => {
    const id = generateRandomID();
    const inputAnchorTemplates = [
      { name: "Input 1", valueType: VALUETYPES.BOOL },
      { name: "Input 2", valueType: VALUETYPES.BOOL },
    ]
    const logicItem = generateLogicDefaultItem(VALUETYPES.BOOL, "and", inputAnchorTemplates, { title: "AND Logic" });

    addItem(id, logicItem);
  }

  const addLTLogic = () => {
    const id = generateRandomID();
    const inputAnchorTemplates = [
      { name: "Input 1", valueType: VALUETYPES.NUMBER },
      { name: "Input 2", valueType: VALUETYPES.NUMBER },
    ]
    const logicItem = generateLogicDefaultItem(VALUETYPES.NUMBER, "lt", inputAnchorTemplates, { title: "Less than Logic" });

    addItem(id, logicItem);
  }

  const addBetweenLogic = () => {
    const id = generateRandomID();
    const inputAnchorTemplates = [
      { name: "Input", valueType: VALUETYPES.NUMBER },
      { name: "GTE", valueType: VALUETYPES.NUMBER },
      { name: "LTE", valueType: VALUETYPES.NUMBER },
    ]

    const logicItem = generateLogicDefaultItem(VALUETYPES.NUMBER, "between", inputAnchorTemplates, { title: "Between Logic" })
    addItem(id, logicItem)
  }

  const addDebug = () => {
    addItem(generateRandomID(), generateDebugDefaultItem())
  }

  const regenContext = async () => {
    const patientData = await genRandomPatient()
    setContext(patientData);
  }


  return (
    <div className="container mx-auto px-6 py-2">
      <Button color={"red"} onClick={regenContext}><FontAwesomeIcon icon={faSync} className="" /></Button>
      <Button color={itemColors[ITEMTYPES.CONSTANT]} onClick={() => { addConstant(VALUETYPES.BOOL) }}>BOOL Constant</Button>
      <Button color={itemColors[ITEMTYPES.CONSTANT]} onClick={() => { addConstant(VALUETYPES.NUMBER) }}>NUMBER Constant</Button>
      <Button color={itemColors[ITEMTYPES.CONSTANT]} onClick={() => { addConstant(VALUETYPES.STRING) }}>STRING Constant</Button>
      <Button color={itemColors[ITEMTYPES.CONSTANT]} onClick={() => { addConstant(VALUETYPES.DATE) }}>DATE Constant</Button>
      <Button color={itemColors[ITEMTYPES.VARIABLE]} onClick={() => { addVariable(VALUETYPES.NUMBER) }}>NUMBER Variable</Button>
      <Button color={itemColors[ITEMTYPES.VARIABLE]} onClick={() => { addVariable(VALUETYPES.STRING) }}>STRING Variable</Button>
      <Button color={itemColors[ITEMTYPES.VARIABLE]} onClick={() => { addVariable(VALUETYPES.DATE) }}>DATE Variable</Button>
      <Button color={itemColors[ITEMTYPES.VARIABLE]} onClick={() => { addVariable(VALUETYPES.ARRAY) }}>ARRAY Variable</Button>
      <Button color={itemColors[ITEMTYPES.VARIABLE]} onClick={() => { addVariable(VALUETYPES.ARRAY) }}>ARRAY Variable</Button>
      <Button color={itemColors[ITEMTYPES.LOGIC]} onClick={() => { addANDLogic() }}>AND Logic</Button>
      <Button color={itemColors[ITEMTYPES.LOGIC]} onClick={() => { addLTLogic() }}>LT Logic</Button>
      <Button color={itemColors[ITEMTYPES.LOGIC]} onClick={() => { addBetweenLogic() }}>Between Logic</Button>
      <Button color={itemColors[ITEMTYPES.DEBUG]} onClick={() => { addDebug() }}>Debug</Button>
    </div>
  );
};

export default Controls;