import _ from 'lodash';
import React from 'react';
import useStore from '../../store';

const Dashboard = () => {
  const context = useStore(state => state.context);
  
  return (
    <div className="container mx-auto px-6 py-2">
      <div>
        <h2 className="text-2xl font-semibold">{context?.name && context?.surname ? `${context.name.value}, ${context.surname.value}` : "Loading..."}</h2>
        <div className="flex">
          {_.map(context, (value, index) => {
            
            return (
              <div className="mr-4 py-4">
                <div className="font-bold">{value.name}</div>
                <div>{Array.isArray(value?.value) ? `Liste[${value.value.length}]` : JSON.stringify(value.value)}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;