import React from 'react';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { valueTypeColors } from '../constants/valueProperties';

import useStore from './../store';


const ItemInputAnchor = ({ name, selected, valueType, handleClick }) => {
  return (
    <div className="mb-4 flex items-center">
      <FontAwesomeIcon icon={faCircle} onClick={handleClick} className={`${selected ? `text-${valueTypeColors[valueType]}-400 hover:text-${valueTypeColors[valueType]}-500` : `text-${valueTypeColors[valueType]}-300 hover:text-${valueTypeColors[valueType]}-200`} mr-1`} style={{ marginLeft: "-0.5rem" }} />
      {/* set position of the label to the anchor position */}
      <label className="uppercase tracking-wide text-gray-500 text-xs font-bold" >{name}</label>
    </div>
  )
}

const ItemOutputAnchor = ({ name, selected, valueType, handleClick }) => {

  return (
    <div className="mb-4 flex items-center">
      <label className="uppercase tracking-wide text-gray-500 text-xs font-bold">{name}</label>
      <FontAwesomeIcon icon={faCircle} onClick={handleClick} className={`${selected ? `text-${valueTypeColors[valueType]}-400 hover:text-${valueTypeColors[valueType]}-500` : `text-${valueTypeColors[valueType]}-300 hover:text-${valueTypeColors[valueType]}-200`}  ml-1`} style={{ marginRight: "-0.5rem" }} />
    </div>
  )
}


const ItemInputNodeSelect = ({ inputID, nodeOptions, handleChange }) => (
  <div className="mb-2">
    <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" for={`input-${inputID}`}>Input {inputID}</label>
    <select onChange={((e) => handleChange(inputID, e.target.value))} className="no-drag block appearance-none w-full border border-gray-200 text-gray-700 py-1 px-2 pr-5 rounded leading-tight focus:outline-none" id={`input-${inputID}`}>
      {_.map(nodeOptions, (itemID) => (
        <option key={itemID} value={itemID}>{itemID}</option>
      ))}
    </select>
  </div>
)

const ItemAnchor = ({ id, anchor }) => {
  const { anchorID, name, type, valueType } = anchor;

  const selectedAnchors = useStore(state => state.selectedAnchors);
  const addSelectedAnchor = useStore(state => state.addSelectedAnchor);
  const toggleSelectedAnchor = useStore(state => state.toggleSelectedAnchor);
  const clearSelectedAnchors = useStore(state => state.clearSelectedAnchors)

  const addConnection = useStore(state => state.addConnection);


  const handleClick = () => {
    const newSelectedAnchor = { id, ...anchor };
    toggleSelectedAnchor(newSelectedAnchor);
    console.log(newSelectedAnchor);

    if (selectedAnchors.length === 1) {
      const [anchor1] = selectedAnchors;
      const anchor2 = newSelectedAnchor;

      if ((anchor1.type !== anchor2.type) && (anchor1.id !== anchor2.id) && (anchor1.valueType === anchor2.valueType)) {
        console.log("yeeeah!")
        addConnection(anchor1, anchor2);
      }
      clearSelectedAnchors();
    }
  }

  const isSelected = () => {
    const index = selectedAnchors.findIndex((currentAnchor) => (currentAnchor.type === type && currentAnchor.id === id && currentAnchor.anchorID === anchorID))
    return index !== -1;
  }

  if (type === "input") {
    return <ItemInputAnchor name={name} valueType={valueType} selected={isSelected()} handleClick={handleClick} />
  } else {
    return <ItemOutputAnchor name={name} valueType={valueType} selected={isSelected()} handleClick={handleClick} />
  }
}

export default ItemAnchor;