import * as ITEMTYPES from '../../constants/itemTypes';
import { valueTypeNames } from '../../constants/valueProperties';
import * as VALUETYPES from '../../constants/valueTypes';
import _ from 'lodash'

// TODO(Jan): anchorID needs to be the actual index of the anchor in the anchors array. Switch to use Objects with key as id or find index by property

// generate default value for AND node
export const generateANDDefaultItem = () => {
  const x = Math.floor(Math.random() * 500);
  const y = Math.floor(Math.random() * 500);

  const width = 250;

  return {
    title: "AND Gate",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.AND,
    params: {
      nodes: []
    },
    anchors: [
      { type: "input", name: "Input 1", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + 0, y: y + 55, xOffset: 0, yOffset: 55 },
      { type: "input", name: "Input 2", valueType: VALUETYPES.BOOL, anchorID: 1, x: x + 0, y: y + 88, xOffset: 0, yOffset: 88 },
      { type: "output", name: "Output 1", valueType: VALUETYPES.BOOL, anchorID: 2, x: x + width, y: y + 55, xOffset: width, yOffset: 55 }
    ]
  }
}

export const generateORDefaultItem = () => {
  const x = Math.floor(Math.random() * 500);
  const y = Math.floor(Math.random() * 500);

  const width = 250;

  return {
    title: "OR Gate",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.OR,
    params: {
      nodes: []
    },
    anchors: [
      { type: "input", name: "Input 1", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + 0, y: y + 55, xOffset: 0, yOffset: 55 },
      { type: "input", name: "Input 2", valueType: VALUETYPES.BOOL, anchorID: 1, x: x + 0, y: y + 88, xOffset: 0, yOffset: 88 },
      { type: "output", name: "Output 1", valueType: VALUETYPES.BOOL, anchorID: 2, x: x + width, y: y + 55, xOffset: width, yOffset: 55 }
    ]
  }
}

export const generateNOTDefaultItem = () => {
  const x = Math.floor(Math.random() * 500);
  const y = Math.floor(Math.random() * 500);

  const width = 250;

  return {
    title: "NOT Gate",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.NOT,
    params: {
      nodes: []
    },
    anchors: [
      { type: "input", name: "Input 1", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + 0, y: y + 55, xOffset: 0, yOffset: 55 },
      { type: "output", name: "Output 1", valueType: VALUETYPES.BOOL, anchorID: 1, x: x + width, y: y + 55, xOffset: width, yOffset: 55 }
    ]
  }
}


export const generateConditionDefaultItem = () => {
  const x = Math.floor(Math.random() * 500);
  const y = Math.floor(Math.random() * 500);

  const width = 300;

  return {
    title: "Condition",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.CONDITION,
    params: {
    },
    anchors: [
      { type: "output", name: "Output 1", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + width, y: y + 60, xOffset: width, yOffset: 60 }
    ]
  }
}

export const generateOutputDefaultItem = () => {
  const x = Math.floor(Math.random() * 500);
  const y = Math.floor(Math.random() * 500);

  const width = 250;

  return {
    title: "Output",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.OUTPUT,
    params: {
      nodes: []
    },
    anchors: [
      { type: "input", name: "Input 1", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + 0, y: y + 58, xOffset: 0, yOffset: 58 },
    ]
  }
}


/* above this only outdated item generation */

export const generateConstantDefaultItem = (valueType) => {
  const x = 100 + Math.floor(Math.random() * 40);
  const y = 100 + Math.floor(Math.random() * 40);

  const width = 300;

  const yOffsetBase = 60;


  return {
    title: "Constant",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.CONSTANT,
    outputValueType: valueType,
    params: {
    },
    outputAnchors: [
      { type: "output", name: "Output", valueType, anchorID: 0, x: x + width, y: y + yOffsetBase, xOffset: width, yOffset: yOffsetBase }
    ],
    inputAnchors: []
  }
}


export const generateVariableDefaultItem = (valueType) => {
  const x = 100 + Math.floor(Math.random() * 40);
  const y = 100 + Math.floor(Math.random() * 40);

  const width = 300;

  const yOffsetBase = 60;


  return {
    title: "Variable",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.VARIABLE,
    outputValueType: valueType,
    params: {
    },
    outputAnchors: [
      { type: "output", name: "Output", valueType, anchorID: 0, x: x + width, y: y + yOffsetBase, xOffset: width, yOffset: yOffsetBase }
    ],
    inputAnchors: []
  }
}

export const generateLogicDefaultItem = (evalFunctionType, evalFunctionKey, inputAnchorsTemplate, options = {}) => {
  const x = 100 + Math.floor(Math.random() * 40);
  const y = 100 + Math.floor(Math.random() * 40);

  const width = 220;

  const yOffsetBase = 56;
  const yOffset = 32;

  const inputAnchors = inputAnchorsTemplate.map((template, index) => (
    {
      type: "input",
      anchorID: index,
      x: x,
      y: y + yOffsetBase + yOffset * index,
      xOffset: 0,
      yOffset: yOffsetBase + yOffset * index,
      ...template,
    }
  ))


  return {
    title: "Logic",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.LOGIC,
    outputValueType: VALUETYPES.BOOL,
    params: {
      evalFunctionType,
      evalFunctionKey,
      evalFunctionArgCount: inputAnchors.length,
    },
    outputAnchors: [
      { type: "output", name: "Output", valueType: VALUETYPES.BOOL, anchorID: 0, x: x + width, y: y + yOffsetBase, xOffset: width, yOffset: yOffsetBase }
    ],
    inputAnchors,
    ...options
  }
}


export const generateDebugDefaultItem = () => {
  const x = 100 + Math.floor(Math.random() * 40);
  const y = 100 + Math.floor(Math.random() * 40);

  const width = 220;

  const yOffsetBase = 56;
  const yOffset = 32;

  let index = 0
  const inputAnchors = _.map(VALUETYPES, (value, key) => {
    console.log(value);
    console.log(key);
    const inputAnchor = {
      type:"input",
      name:key,
      valueType: value,
      anchorID: index,
      x: x,
      y: y + yOffsetBase + yOffset * index,
      xOffset:0,
      yOffset: yOffsetBase + yOffset * index,
    }
    index ++;
    return inputAnchor
  })

  return {
    title: "Debug",
    x,
    y,
    width,
    height: null,
    type: ITEMTYPES.DEBUG,
    outputValueType: null,
    params: {
    },
    outputAnchors: [
    ],
    inputAnchors
  }
}