import React, { memo } from 'react';

const ItemBackgroundGrid = memo(() => (
  <>
    <defs>
      <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
        <path d="M 8 0 L 0 0 0 8" className="stroke-current text-gray-100" fill="none" stroke="gray" stroke-width="0.5" />
      </pattern>
      <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
        <rect width="80" height="80" fill="url(#smallGrid)" />
        <path d="M 80 0 L 0 0 0 80" className="stroke-current text-gray-200" fill="none" stroke="gray" stroke-width="1" />
      </pattern>
    </defs>

    <rect width="100%" height="100%" fill="url(#grid)" />
  </>
))

export default ItemBackgroundGrid;