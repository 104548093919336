import * as VALUETYPES from './valueTypes';

export const valueTypeColors = {
  [VALUETYPES.BOOL]: "red",
  [VALUETYPES.NUMBER]: "yellow",
  [VALUETYPES.ARRAY]: "blue",
  [VALUETYPES.STRING]: "pink",
  [VALUETYPES.DATE]: "green"
}

export const valueTypeNames = {
  [VALUETYPES.BOOL]: "Boolean",
  [VALUETYPES.NUMBER]: "Number",
  [VALUETYPES.ARRAY]: "Array",
  [VALUETYPES.STRING]: "String",
  [VALUETYPES.DATE]: "Date"
}