import React, { useCallback, memo, useState, useEffect } from 'react';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import * as VALUETYPES from '../../constants/valueTypes'
import { valueTypeNames } from '../../constants/valueProperties';

import useStore from '../../store';


const StringInput = ({ value, setValue, valueType }) => (
  <input type="text" placeholder={valueTypeNames[valueType].toUpperCase()} className="px-1" value={value} onChange={(e) => setValue(e.target.value)} />
)


const NumberInput = ({ value, setValue, valueType }) => (
  <input type="number" placeholder={valueTypeNames[valueType].toUpperCase()} className="px-1" value={value} onChange={(e) => setValue( parseFloat(e.target.value))} />
)

// HACK(Jan): Use the string date "internally" as value für the input such that it can be an invalid date
const DateInput = ({ value, setValue, valueType }) => {
  const [stringDate, setStringDate] = useState("");

  const handleChange = (e) => {
    console.log(e.target.value);
    setStringDate(e.target.value)
    setValue(new Date(e.target.value))
  }

  return (
    <input type="date" className="px-1" value={stringDate || ""} onChange={handleChange} />
)}

// HACK(Jan): Added height to make line up anchor point with the output anchor circle
// HACK(Jan): Set initial value -> there has to be a better way
const BoolInput = ({ value, setValue, valueType }) => {
  useEffect(() => {
    setValue(value || false)
  },[])

  return (
  <input style={{ height: 18 }} type="checkbox" className="px-1" checked={value} onChange={() => setValue(!value)} />
)}


const MultiTypeInput = (props) => {
  switch(props.valueType){
    case VALUETYPES.STRING:
      return <StringInput {...props} />
    case VALUETYPES.DATE:
      return <DateInput {...props} />
    case VALUETYPES.NUMBER:
      return <NumberInput {...props} />
    case VALUETYPES.BOOL:
      return <BoolInput {...props} />
    default:
      return <StringInput {...props} />
  }
}


const ConstantItem = memo(({ id }) => {
  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);
  const removeConnectionsWithID = useStore(state => state.removeConnectionsWithID);

  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeConnectionsWithID(id);
    removeItem(id);
  }

  const setValueToItem = (value) => {
    setItem(id, {
      ...item,
      params: {
        ...item.params,
        value
      }
    })
  }

  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title} [${valueTypeNames[item.outputValueType].toUpperCase()}]`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between pt-2 items-baseline">
        <div className="no-drag pl-4 flex flex-col space-y-2">
          <MultiTypeInput value={item.params.value} setValue={setValueToItem} valueType={item.outputValueType} />
        </div>
        <div className="flex-shrink-0">
          {item.outputAnchors.map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
      </div>
    </DraggableItem>
  )
})

export default ConstantItem;