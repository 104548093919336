export class AND {
  constructor(l, r) {
    this.l = l;
    this.r = r;
  }

  eval() {
    return this.l.eval() && this.r.eval();
  }
}

export class OR {
  constructor(l, r) {
    this.l = l;
    this.r = r;
  }

  eval() {
    return this.l.eval() || this.r.eval();
  }
}

export class NOT {
  constructor(c) {
    this.c = c;
  }

  eval() {
    return !this.c.eval()
  }
}

export class Condition {
  constructor(propertyValue, value, compareFunction, ...rest) {
    this.propertyValue = propertyValue;
    this.value = value;
    this.compareFunction = compareFunction;
    this.additionalParams = rest;
  }

  eval() {
    return this.compareFunction(this.propertyValue, this.value, ...this.additionalParams)
  }
}

export class Output {
  constructor(c, textBlockId) {
    this.textBlockId = textBlockId;
    this.c = c;
  }

  eval() {
    return this.c.eval();
  }
}

export class Default {
  constructor(defaultValue = false) {
    this.defaultValue = defaultValue;
  }

  eval() {
    return this.defaultValue
  }
}

export class Constant {
  constructor(value) {
    this.value = value;
  }

  eval() {
    return this.value;
  }
}

export class Variable {
  constructor(contextValue) {
    this.contextValue = contextValue;
  }

  eval() {
    return this.contextValue; 
  }
}

export class Logic {
  constructor(evalFunction, children) {
    this.evalFunction = evalFunction;
    this.children = children;
  }

  eval() {
    return this.evalFunction(...this.children.map((child) => child.eval()))
  }
}

export class Error {
  constructor(errorType) {
    this.errorType = errorType;
  }

  eval() {
    return this.errorType;
  }
}