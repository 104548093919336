import _ from 'lodash';
import * as VALUETYPES from '../../constants/valueTypes';

// generate random id -> generate UUID
export const generateRandomID = () => (
  (() => (Math.floor(Math.random() * 100000)).toString())()
)


const randomInt = (max) => {
  return Math.floor(Math.random() * max);
}

const randomDate = (start, end) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const randomMeasurement = (name, max, unit = "") => (
  { name, time: randomDate(new Date(2018, 0, 1), new Date()), value: Math.random() * max, unit }
)

export const genRandomPatient = async () => {
  const randomPerson = await (await (await fetch('https://randomuser.me/api/')).json()).results[0];
  console.log("random Person data", randomPerson);

  const patientData = {
    "surname": { name: "Vorname", value: randomPerson.name.first, type: VALUETYPES.STRING },
    "name": { name: "Name", value: randomPerson.name.last, type: VALUETYPES.STRING },
    "gender": { name: "Geschlecht", value: randomPerson.gender, type: VALUETYPES.STRING },
    "dob": { name: "Geburtsdatum", value: new Date(randomPerson.dob.date), type: VALUETYPES.DATE },
    "age": { name: "Alter", value: randomPerson.dob.age, type: VALUETYPES.NUMBER },
    "bloodIron": { name: "Eisenwerte", value: Array(randomInt(24)).fill().map(() => randomMeasurement("bloodIron", 15, "ug")), type: VALUETYPES.ARRAY },
    "inflammationValue": { name: "Entzündungswerte", value: Array(randomInt(24)).fill().map(() => randomMeasurement("inflammationValue", 160, "")), type: VALUETYPES.ARRAY }
  }

  return patientData
}