import React from 'react';

const Nav = () => {
  return (
    <nav className="shadow-md sticky top-0 bg-white">
      <div className="container mx-auto z-50 px-6 py-2 flex justify-between items-center">
        <a className="font-bold text-2xl lg:text-4xl" href="https://google.de">
          Condition<span className="text-green-200">er</span>
        </a>
      </div>
    </nav>
  );
};

export default Nav;