import * as itemTypes from './itemTypes';

export const itemColors = {
  [itemTypes.AND]: "green",
  [itemTypes.OR]: "green",
  [itemTypes.NOT]: "green",
  [itemTypes.CONDITION]: "purple",
  [itemTypes.OUTPUT]: "gray",
  [itemTypes.CONSTANT]: "purple",
  [itemTypes.VARIABLE]: "fuchsia",
  [itemTypes.LOGIC]: "green",
  [itemTypes.DEBUG]: "gray",
}