import React, { useCallback, memo } from 'react';
import produce from 'immer';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import { parseGraphData } from '../graph/graphParser';

import useStore from '../../store';


const DebugItem = memo(({ id }) => {
  const items = useStore(state => state.items)

  const connections = useStore(state => state.connections);
  const currentConnections = connections.filter((connection) => connection.inputAnchor.id === id)
  const removeConnectionsWithID = useStore(state => state.removeConnectionsWithID);

  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);

  // HACK(Jan): subscribe to context here to update debug component when context changes
  const context = useStore(state => state.context);

  const tempInputAnchors = [...item.inputAnchors];

  _.forEach(currentConnections, (connection) => {
    const outputID = connection.outputAnchor.id;
    const graph = parseGraphData(items, outputID)
    const graphResult = graph.eval();
    console.log(graphResult);
    tempInputAnchors[connection.inputAnchor.anchorID] = {
      ...tempInputAnchors[connection.inputAnchor.anchorID],
      name: JSON.stringify(graphResult),
    }
  })


  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeConnectionsWithID(id)
    removeItem(id);
  }


  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title} - ID: ${id.toString()}`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between items-baseline pt-2">
        <div className="flex-shrink-0">
          {tempInputAnchors.map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
      </div>
    </DraggableItem>
  )
})

export default DebugItem;