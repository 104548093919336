import * as GraphClasses from './graphClasses';
import * as ITEMTYPES from '../../constants/itemTypes';
import * as VALUETYPES from '../../constants/valueTypes';
import useStore from '../../store';

const evalFunctions = {
  [VALUETYPES.BOOL]: {
    and: (val1, val2) => val1 && val2,
  },
  [VALUETYPES.NUMBER]: {
    lt: (val1,val2) => val1 < val2,
    between: (input, val1, val2) => val1 <= input && input <= val2,
  }
}

export const parseGraphData = (graphData, currentDataKey) => {
  const context = useStore.getState().context;
  const currentData = graphData[currentDataKey];

  if(!currentData) return new GraphClasses.Error("Not Connected");

  switch(currentData.type){
    case ITEMTYPES.CONSTANT:
      const value = currentData.params.value;
      return new GraphClasses.Constant(value)
    case ITEMTYPES.VARIABLE:
      if (!context[currentData.params.contextKey]) return new GraphClasses.Error("Property not found in context")
      const contextValue = context[currentData.params.contextKey].value;
      return new GraphClasses.Variable(contextValue);
    case ITEMTYPES.LOGIC:
      if (currentData.params.evalFunctionArgCount !== currentData.params.nodes.length) return new GraphClasses.Error("Not all anchors connected")
      const evalFunction = evalFunctions?.[currentData.params.evalFunctionType]?.[currentData.params.evalFunctionKey];
      if (!evalFunction) return new GraphClasses.Error("Eval function not found");
      const newGraphClasses = currentData.params.nodes.map((node) => parseGraphData(graphData, node));
      return new GraphClasses.Logic(evalFunction, newGraphClasses);
    default:
      return new GraphClasses.Error("Invalid Type")
  }

}