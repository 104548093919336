import React, { memo, useRef, } from 'react';
import Draggable from 'react-draggable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { itemColors } from '../constants/itemProperties';

const DraggableItemTitle = ({ title, removeCurrentItem }) => (
  <div className="flex justify-between items-center text-gray-600 font-bold pl-4 pr-2 mb-2">
    <div className="cursor-default">{title}</div>
    <FontAwesomeIcon icon={faTimesCircle} className="cursor-pointer text-xl hover:text-red-600" onClick={removeCurrentItem} />
  </div>
)


const DraggableItem = memo(({ item, setCurrentItem, children }) => {
  const itemRef = useRef(null);

  const handleDrag = (e) => {
    const { movementX, movementY } = e;
    const { clientWidth, clientHeight } = itemRef.current;

    const x = item.x + movementX;
    const y = item.y + movementY;

    const { inputAnchors, outputAnchors } = item;
    const updatedInputAnchors = inputAnchors?.map((anchor) => (
      {
        ...anchor,
        x: x + anchor.xOffset,
        y: y + anchor.yOffset
      }
    ));

    const updatedOutputAnchors = outputAnchors?.map((anchor) => (
      {
        ...anchor,
        x: x + anchor.xOffset,
        y: y + anchor.yOffset
      }
    ));




    setCurrentItem({
      ...item,
      x,
      y,
      width: clientWidth,
      height: clientHeight,
      inputAnchors: updatedInputAnchors,
      outputAnchors: updatedOutputAnchors
    })
  }

  return (
    <Draggable position={{ x: item.x, y: item.y }} onDrag={handleDrag} bounds="parent" cancel=".no-drag">
      {/* don't interpolate classes like that for some reason -> find better way */}
      <div ref={itemRef} className={`absolute bg-${itemColors[item.type]}-200 hover:bg-${itemColors[item.type]}-300 shadow-md pb-4 pt-2 rounded`} style={{ width: item.width }}>
        {children}
      </div>
    </Draggable>
  )
})

export { DraggableItemTitle }
export default DraggableItem;