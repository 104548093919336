import React, { useCallback, memo, useState } from 'react';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import * as VALUETYPES from '../../constants/valueTypes'
import { valueTypeNames } from '../../constants/valueProperties';

import useStore from '../../store';


const VariableItem = memo(({ id }) => {
  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);
  const removeConnectionsWithID = useStore(state => state.removeConnectionsWithID);

  const context = useStore(state => state.context);

  const typeContext = _.pickBy(context,(property) => property.type === item.outputValueType)

  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeConnectionsWithID(id);
    removeItem(id);
  }

  const setContextKeyToItem = (e) => {
    const { value } = e.target;
    setItem(id, {
      ...item,
      params: {
        ...item.params,
        contextKey: value,
      }
    })
  }

  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title} [${valueTypeNames[item.outputValueType].toUpperCase()}]`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between pt-2 items-baseline">
        <div className="no-drag pl-4 flex flex-col space-y-2">
          <select  value={item.params.contextKey || ""} onChange={setContextKeyToItem}>
            <option value=""  disabled selected hidden>Property</option>
            {_.map(typeContext, ((contextItem, key) => <option value={key}>{contextItem.name}</option>))}
          </select>
        </div>
        <div className="flex-shrink-0">
          {item.outputAnchors.map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
      </div>
    </DraggableItem>
  )
})

export default VariableItem;