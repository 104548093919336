import React, { useCallback, memo } from 'react';
import produce from 'immer';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import useStore from '../../store';

// obsolete
const OutputItem = memo(({ id }) => {
  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);

  const textBlocks = useStore(state => state.textBlocks);
  const textBlocksKeys = Object.keys(textBlocks);

  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeItem(id);
  }

  const handleTextBlockKeyChange = (e) => {
    const { value } = e.target;
    setItem(id, {
      ...item,
      params: {
        ...item.params,
        textBlockKey: value,
      }
    })
  }

  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title} - ID: ${id.toString()}`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between items-baseline pt-2">
        <div className="flex-shrink-0">
          {item.anchors.filter((anchor) => anchor.type === "input").map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
        <div className="no-drag pr-4 flex flex-col">
          <select className="uppercase" onChange={handleTextBlockKeyChange}>
            <option value={item.params.textBlockKey || ""} className="uppercase" disabled selected hidden>Textblock ID</option>
            {textBlocksKeys.map((key) => <option value={key} className="uppercase">{key}</option>)}
          </select>
        </div>
      </div>
    </DraggableItem>
  )
})

export default OutputItem;