import React, { useCallback, memo, useState, useEffect, useRef, useMemo } from 'react';
import produce from 'immer';
import _ from 'lodash';

import DraggableItem, { DraggableItemTitle } from '../DraggableItem';
import ItemAnchor from '../ItemAnchor';

import useStore from '../../store';

// obsolete
const GateItem = memo(({ id }) => {
  const item = useStore(useCallback(state => state.items[id], [id]));
  const setItem = useStore(state => state.setItem);
  const removeItem = useStore(state => state.removeItem);

  const setCurrentItem = (newItem) => {
    setItem(id, newItem);
  }

  const removeCurrentItem = () => {
    removeItem(id);
  }

  const setInput = (inputID, itemID) => {
    setItem(id, produce(item, draft => {
      draft.params.nodes[inputID] = itemID;
    }))
  }

  return (
    <DraggableItem item={item} setCurrentItem={setCurrentItem}>
      <DraggableItemTitle title={`${item.title} - ID: ${id.toString()}`} removeCurrentItem={removeCurrentItem} />
      <div className="flex justify-between pt-2">
        <div>
          {item.anchors.filter((anchor) => anchor.type === "input").map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
        <div>
          {item.anchors.filter((anchor) => anchor.type === "output").map((anchor) => (
            <ItemAnchor id={id} anchor={anchor} />
          ))}
        </div>
      </div>
    </DraggableItem>
  )
})

export default GateItem;