import create from 'zustand';
import { persist } from "zustand/middleware"
import produce from 'immer';
import _ from 'lodash';


const useStore = create((set, get) => ({
  context: {},
  setContext: (newContext) => set(state => ({ context: newContext })),

  textBlocks: {},
  setTextBlocks: (newTextBlocks) => set(state => ({textBlocks: newTextBlocks})),

  items: {},

  setItems: (newItems) => set(state => ({items: newItems})),

  addItem: (id, newItem) => set(produce(state => {
    state.items[id] = newItem;
  })),
  // how to use produce while still being able to call functions from state?
  // BUG(Jan): when a connection is removed via removeConnections debug item is updated properly but not when connections is removed via removeItem
  // HACK(Jan): Manually call removeConnectionsWithID in the components instead of in the state function
  removeItem: (id) => set((state) => {
    state.clearSelectedAnchors();
    //state.removeConnectionsWithID(id);

    const tempItems = _.cloneDeep(state.items);
    delete tempItems[id];

    return { items: tempItems };
  }),
  setItem: (id, newItem) => set(produce(state => {
    state.items[id] = newItem;
  })),

  selectedItems: [],
  addSelectedItem: (newID) => set(produce(state => {
    state.selectedItems.push(newID)
  })),
  toggleSelectedItem: (toggleID) => set(produce(state => {
    const index = state.selectedItems.findIndex((id) => id === toggleID)
    if (index !== -1) {
      state.selectedItems.splice(index, 1);
    } else {
      state.selectedItems.push(toggleID);
    }
  })),

  selectedAnchors: [],
  addSelectedAnchor: (newAnchor) => set(produce(state => {
    state.selectedAnchors.push(newAnchor)
  })),

  removeSelectedAnchor: (removeAnchor) => set(produce(state => {
    const index = state.selectedAnchors.findIndex((anchor) => anchor.id === removeAnchor.id && anchor.anchorID === removeAnchor.anchorID)
    if (index !== -1) {
      state.selectedAnchors.splice(index, 1);
    }
  })),

  toggleSelectedAnchor: (toggleAnchor) => set(produce(state => {
    const index = state.selectedAnchors.findIndex((anchor) => anchor.id === toggleAnchor.id && anchor.anchorID === toggleAnchor.anchorID)
    if (index !== -1) {
      state.selectedAnchors.splice(index, 1);
    } else {
      state.selectedAnchors.push(toggleAnchor);
    }
  })),

  clearSelectedAnchors: () => set(produce(state => {
    state.selectedAnchors = [];
  })),


  connections: [],

  setConnections: (newConnections) => set( state => ({connections: newConnections})),

  // run state.generateItemParamsFromConnections(newConnections) anytimes a connection is added/removed to update the nodes in the items array
  addConnection: (anchor1, anchor2) => set( state => {
    const inputAnchor = anchor1.type === "input" ? anchor1 : anchor2;
    const outputAnchor = anchor2.type === "output" ? anchor2 : anchor1;
    
    const noDoubleInputConnection = state.connections.filter((connection, connectionInd) => {
      return !(inputAnchor.id === connection.inputAnchor.id && inputAnchor.anchorID === connection.inputAnchor.anchorID)
    })

    const newConnections = [...noDoubleInputConnection, { inputAnchor, outputAnchor }];

    state.generateItemParamsFromConnections(newConnections);

    return { connections: newConnections}
  }),

  removeConnection: (index) => set(state => {
    const newConnections = state.connections.filter((_, currentIndex) => (currentIndex !== index))

    state.generateItemParamsFromConnections(newConnections);
    return { connections: newConnections }
  }),

  // not tested
  removeConnectionWithAnchor: (id, anchorID) => set(state => {
    const newConnections = state.connections.filter((connection, currentIndex) => {
      return !((connection.inputAnchor.id === id && connection.inputAnchor.anchorID === anchorID) || (connection.outputAnchor.id === id && connection.outputAnchor.anchorID === anchorID))
    })

    state.generateItemParamsFromConnections(newConnections);
    return { connections: newConnections }
  }),

  removeConnectionsWithID: (id) => set(state => {
    const newConnections = state.connections.filter(connection => (!(connection.inputAnchor.id === id || connection.outputAnchor.id === id)))

    state.generateItemParamsFromConnections(newConnections);
    return { connections: newConnections }
  }),

  generateItemParamsFromConnections: (connections) => set(state => {
    const newItems = _.cloneDeep(state.items);
    _.forEach(newItems, (item, itemInd) => {
      // incomingNodes are the output nodes of the connected item
      const incomingNodes = connections.filter(connection => connection.inputAnchor.id === itemInd).map(connection => connection.outputAnchor.id);
      item.params.nodes = incomingNodes;
    })

    return {items: newItems}
  }),

  // TODO(Jan): Generate connections from ItemParams when fetching a existing graph from db

}))

export default useStore;