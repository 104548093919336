import React, { useCallback, memo } from 'react';
import _ from 'lodash';

import { valueTypeColors } from '../constants/valueProperties';

import useStore from './../store';


const ItemConnection = ({ inputAnchor, outputAnchor, handleClick }) => {
  console.log("connection", { inputAnchor, outputAnchor });

  const { id: itemIDInput, anchorID: anchorIDInput, valueType } = inputAnchor;
  const { id: itemIDOutput, anchorID: anchorIDOutput } = outputAnchor;

  const itemInput = useStore(useCallback(state => state.items[itemIDInput], [itemIDInput]));
  const itemOutput = useStore(useCallback(state => state.items[itemIDOutput], [itemIDOutput]));

  const { x: x1, y: y1 } = itemInput.inputAnchors[anchorIDInput];
  const { x: x2, y: y2 } = itemOutput.outputAnchors[anchorIDOutput];


  const dx1 = x1 + (x2 - x1) / 2;
  const dy1 = y1;
  const dx2 = x2 - (x2 - x1) / 2;
  const dy2 = y2;

  return (
    <g>
      {/* <circle cx={dx1} cy={dy1} r="5" fill="#cccccc" />
      <circle cx={dx2} cy={dy2} r="5" fill="#cccccc" /> */}
      <path onClick={handleClick} d={`M ${x1},${y1} C ${dx1},${dy1} ${dx2},${dy2} ${x2} ${y2}`} className={`stroke-current text-${valueTypeColors[valueType]}-300`} stroke="#A0AEC0" strokeWidth="4px" fill="none" />
    </g>
  )
}

const ItemConnections = memo(() => {
  const connections = useStore(state => state.connections);
  const removeConnection = useStore(state => state.removeConnection)

  return (
    <>
      {connections.map((connection, ind) => (
        <ItemConnection key={ind} {...connection} handleClick={() => {removeConnection(ind)}} />
      ))}
    </>
  )
})

export default ItemConnections;